import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './Header.scss';
import { AppState } from 'modules/app/reducers';
import { store } from 'modules/app/store';
import { actions as workspaceActions } from 'modules/workspace/actions';
import { HeaderUtils } from './utils/headerUtils';
import { ModuleUtils } from 'utils/moduleUtils';

interface HeaderProps extends RouteComponentProps {
    history: any,
    header: {
        language: string
    },
    currentRoute: any
}

interface HeaderState {
    pageLanguage: string,
    content: any
}

const mapStateToProps = (state: AppState) => {
    return {
        header: state.workspace.header,
        currentRoute: state.workspace.currentRoute
    }
}

class HeaderComponent extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);
        let { header } = this.props;
        this.state = {
            pageLanguage: header.language,
            content: HeaderUtils.getHeaderContent()
        }
    }

    componentDidMount() {
        let{ currentRoute, header } = this.props;
        let { language } = header;
        this.setLanguage({language: language, path: currentRoute[language]}, null);
    }

    componentDidUpdate() {
        let { pageLanguage } = this.state;
        let { language } = this.props.header;
        if(language !== pageLanguage) {
            this.setState({
                    content: HeaderUtils.getHeaderContent(language),
                    pageLanguage: language
                }
            );
        }
    }

    setLanguage = (context: any, event: any) => {
        let { language, path } = context;
        store.dispatch(workspaceActions.setLanguage(language));
        ModuleUtils.gotoPage(path, this.props, false);
    }

    render() {
        let { content } = this.state;
        let { entry1, entry2, entry3, entry4 } = content;
        let{ currentRoute } = this.props;
        return (
            <div id="main-header">
                <div className="container">
                    <div className="header-row">

                        <div className="header-column">
                            <div className="logo">
                                <button onClick={ModuleUtils.gotoPage.bind(this, entry1.anchor.href, this.props, false)}>
                                    <img alt={entry1.img.alt} src={entry1.img.src}/>
                                </button>
                            </div>
                        </div>

                        <div className="header-column">
                            <div className="social-bottom">
                                <h4>{entry2.title}</h4>
                                <img alt={entry2.img1.alt} src={entry2.img1.src}/><br/>
                                <img alt={entry2.img2.alt} src={entry2.img2.src}/>
                            </div>
                        </div>

                        <div className="header-column">
                            <div className="social-bottom simulate-budget">
                                <button onClick={ModuleUtils.gotoPage.bind(this, entry3.anchor.href, this.props, false)}>
                                    <img alt={entry3.img.alt} src={entry3.img.src}/>
                                </button>
                            </div>
                        </div>

                        <div className="header-column">
                            <div className="social-head">
                                <button onClick={this.setLanguage.bind(this, {language: 'pt', path: currentRoute['pt']})}>
                                    <img alt="bandeira-pt" className="social-bottom" src="/images/pt.jpg"/>
                                </button> 
                                <button onClick={this.setLanguage.bind(this, {language: 'en', path: currentRoute['en']})}>
                                    <img alt="bandeira-en" className="social-bottom" src="/images/uk.jpg"/>
                                </button>
                                <h5>{entry4.title}</h5>
                                <a className="fa fa-facebook" href="https://www.facebook.com/pintorespinturadeinteriores" rel="noopener noreferrer" target="_blank"> </a> 
                                <a className="fa fa-twitter" href="https://twitter.com/Pintorespaint4u" rel="noopener noreferrer" target="_blank"> </a>
                                <a className="fa fa-google-plus" href="https://plus.google.com/b/107313983256579920799/107313983256579920799" rel="noopener noreferrer" target="_blank"> </a>
                                <a className="fa fa-youtube-play" href="https://www.youtube.com/channel/UCOBbWfIfrtVxGXaJND93LUQ" rel="noopener noreferrer" target="_blank"> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const HeaderWithRouter = withRouter(HeaderComponent);

export const Header = connect(mapStateToProps)(HeaderWithRouter);