import React from 'react';
import './Loader.scss';
import { MoonLoader } from 'react-spinners';
import { LoadingComponentProps } from 'react-loadable';
import PageNotFound from '../../pagenotfound';

export class Loader extends React.Component<LoadingComponentProps> {

   
    render() {
      const  { error, pastDelay } = this.props;
      let content = <p>...</p>;
        if (!pastDelay) {
            content = (Loading()
                );
        }
        else if (error) {
            content = (
                <PageNotFound/>
            );
        }
        return (
            <>
                {content}
            </>
        )
    }
}

export function Loading() {
    return (
        <div className="loading-container">
            <MoonLoader size={100} color={'#176DEE'} />
        </div>
    )
}