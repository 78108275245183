import { combineReducers } from 'redux';
import { workspaceReducer, WorkspaceState } from 'modules/workspace/reducers';

export interface AppState {
  workspace: WorkspaceState
}

export const allReducers = {
  workspace: workspaceReducer
};

export const reducers = combineReducers(allReducers);
