import React, { Component } from 'react';
import { Header } from './header/Header';
import { Menu } from './menu/Menu';
import { Footer } from  './footer/Footer';
import { ScrollTop } from './scrolltop/ScrollTop';
interface WorkspaceProps {
    children: any
}

interface WorkspaceState {

}

export class Workspace extends Component<WorkspaceProps, WorkspaceState> {

    render() {
        const { children } = this.props;

        return (
            <>
                <Header/>
                <Menu/>
                    {children}
                    <ScrollTop/>
                <Footer/>
            </>
        );
    }
}