export class HeaderUtils {

    static getHeaderContent(language?: string) {
        let content: any = {
            pt: {
                entry1: {
                    anchor: {
                        href: "/inicio"
                        
                    },
                    img: {
                        alt: "pinturas de interiores",
                        src: "/images/paint4u-pinturas.png"
                    }
                },
                entry2: {
                    title: "Contacte os pintores:",
                    img1: {
                        alt: "telemovel paint4u",
                        src: "/images/btn-telemovel.jpg"
                    },
                    img2: {
                        alt: "telefone paint4u",
                        src: "/images/btn-telefone.jpg"
                    }
                },
                entry3: {
                    anchor: {
                        href: "/orcamento-pinturas"
                    },
                    img: {
                        alt: "orçamento pinturas",
                        src: "/images/orcamento-call.png"
                    }
                },
                entry4: {
                    title: "Siga-nos nas redes sociais:"
                }
            },
            en: {
                entry1: {
                    anchor: {
                        href: "/home"
                    },
                    img: {
                        alt: "interior painting",
                        src: "/images/paint4u-logo-en.png"
                    }
                },
                entry2: {
                    title: "Contact painters:",
                    img1: {
                        alt: "cellphone paint4u",
                        src: "/images/btn-telemovel.jpg"
                    },
                    img2: {
                        alt: "telephone paint4u",
                        src: "/images/btn-telefone.jpg"
                    }
                },
                entry3: {
                    anchor: {
                        href: "/paint-budget"
                    },
                    img: {
                        alt: "paintings budget",
                        src: "/images/btn-budget.png"
                    }
                },
                entry4: {
                    title: "Follow us on social networks:"
                }
            }
        };
        return content[!language ? 'pt' : language];
    }
}