import { types } from './actions';
import { Action } from 'utils/actions';

export interface WorkspaceState {
    header: {
        language: string
    },
    currentRoute: any
}

const initialState: WorkspaceState = {
    header: {
        language: 'pt'
    },
    currentRoute: {
        pt: '/inicio',
        en: '/home'
    }
}

export const workspaceReducer = (state: WorkspaceState = initialState, action: Action): WorkspaceState => {
    switch(action.type) {
        case types.HEADER_LANGUAGE:
            return {
                ...state,
                header: action.payload.header
            };
        case types.CURRENT_ROUTE:
            return {
                ...state,
                currentRoute: action.payload.currentRoute
            }    
        default: 
            return state;    
    }
}