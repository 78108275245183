import './Alert.scss';
import { Alert as MuiAlert, AlertTitle  } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import React from 'react';

interface AlertProps {
    severity: string | any,
    title: string,
    message: string
    handleClose: () => void,
    position?: {
        vertical: 'top' | 'bottom',
        horizontal: 'left' | 'center' | 'right'
    }
}

export const Alert: React.FC<AlertProps> = React.forwardRef((props: AlertProps) => {

    const { severity, title, message , handleClose, 
            position: { vertical = 'top', horizontal = 'right'} = {} } = props;
    return (
        <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical, horizontal }}
            open={severity}
            onClose={handleClose}
            key={`${vertical}-${horizontal}`}
        >
            <MuiAlert severity={severity} onClose={handleClose}>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </MuiAlert>
        </Snackbar>
    )
});
