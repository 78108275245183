import React from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import './Footer.scss';
import { withRouter } from 'react-router-dom';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from "mdbreact";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { ModuleUtils } from 'utils';
import { FooterUtils } from './utils/footerUtils';
import { store } from 'modules/app/store';
import Alert from 'modules/alert';

interface FooterProps {
    history: any,
    location: any,
    match: any
}

interface FooterState {
    email: string,
    emailStatus: string,
    cards: any[],
    pageLanguage: string,
    content: any,
    alertProps: any
}

class FooterComp extends React.Component<FooterProps, FooterState> {

    constructor(props: FooterProps) {
        super(props);
        this.state = {
            email: '',
            emailStatus: '',
            pageLanguage: ModuleUtils.getLanguage(),
            cards: FooterUtils.buildHomeCards(),
            content: FooterUtils.getFooterContent(),
            alertProps: undefined
        }
    }

    componentDidUpdate() {
        let { pageLanguage } = this.state;
        let { language } = store.getState().workspace.header;
        if(language !== pageLanguage) {
            this.setState({
                    cards: FooterUtils.buildHomeCards(language),
                    content: FooterUtils.getFooterContent(language),
                    pageLanguage: language
                }
            );
        }
    }

    //PHP Email scripts need to be in the root of builded application in domain for now i'll put in utils folder
    handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let { email, content } = this.state;
        axios({
          method: 'POST',
          url: `https://www.paint4u.pt/emailUtils/sendEmailNewsLetter.php?email=${email}`
        })
        .then((response: AxiosResponse) => {
            if(response.status === 200) {
                ModuleUtils.gotoPage(content.newsLetterPage, this.props, true);
                this.setState(
                    {
                        alertProps: {
                            severity: 'success', 
                            title: `Sucess${this.state.pageLanguage === 'pt' ? 'o' : ''}`, 
                            message: this.state.pageLanguage === 'pt' ? 
                                'Subscrito na newsletter. Cumprimentos, Equipa Paint4U' :
                                'Quote sent! Regards, Paint4U Team'
                        }
                    }
                );
            }
            else {
                // alert('Mensagem não enviada.');
                this.setState(
                    {
                        alertProps: { 
                            severity: 'error', 
                            title: `Erro${this.state.pageLanguage === 'pt' ? '' : 'r'}`, 
                            message: this.state.pageLanguage === 'pt' ? 'Mensagem não enviada.' : 'Message cannot be sent.' 
                        } 
                    }
                );
            }
        })
        .catch((error: AxiosError) => {
            this.setState(
                {
                    alertProps: { 
                        severity: 'error', 
                        title: `Erro${this.state.pageLanguage === 'pt' ? '' : 'r'}`, 
                        message: this.state.pageLanguage === 'pt' ? 'Erro de Rede' : error.message 
                    } 
                }
            );
            console.log(error.message);
        });
    }

    renderAlert = () => {
        let { alertProps } = this.state;
        return  (
            <Alert 
                {...alertProps} 
                handleClose={() => this.setState({alertProps: undefined})}
            />
        );
    }
    
    emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: event.target.value});
    }

    handleCollapse = (card: any, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let { cards } = this.state;
        let { expanded } = card.content;
        card.content.expanded = !expanded;
        this.setState({cards: [...cards]});
    }

    buildCards = () => {
        let { cards } = this.state;

       return cards.map((card: any, index: number) => {
           let { image, content } = card;
           return (
                <Card key={index} className="card-dimensions" /*onClick={this.handleCollapse.bind(this, card)}*/>
                <CardActionArea>
                    <CardMedia className={image.className}
                        component="img"
                        alt={image.alt}
                        src={image.src}
                        title={image.title}
                    />
                    <CardContent className={content.className} onMouseEnter={this.handleCollapse.bind(this, card)} onMouseLeave={this.handleCollapse.bind(this, card)}>
                        <Typography gutterBottom variant="h5" component="h2">
                            <button onClick={ModuleUtils.gotoPage.bind(this, content.href, this.props, true)}>
                               {content.title}
                            </button>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            <Typography paragraph className="card-intro-text">
                                <b>
                                    {content.intro}
                                </b>
                            </Typography>
                            <Collapse in={card.content.expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography paragraph>
                                        <b>
                                            {content.resume}
                                        </b>
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions onMouseEnter={this.handleCollapse.bind(this, card)} onMouseLeave={this.handleCollapse.bind(this, card)}>
                    <Button size="medium" color="primary">
                        <button onClick={ModuleUtils.gotoPage.bind(this, content.more.href, this.props, true)}>
                            {content.more.text}
                        </button>
                    </Button>
                </CardActions>
            </Card>
           );
       });
    }

    buildTestimonials = () => {
        let { content } = this.state;
        let { testimonials } = content;
        return testimonials.map((data: any, index: number) => {
            return (
                <MDBCarouselItem itemId={index}>
                    <MDBView>
                        <div className="testimonials-content">
                            <p>
                                {data.paragraph}
                            </p>
                            <h6>
                                {data.header}
                            </h6>
                        </div>
                    </MDBView>
                </MDBCarouselItem>
            );
        });
    }

    buildServices = () => {
        let { content } = this.state;
        let { services } = content;

        return services.map((data: any) => {
            return (
                <li>
                    <button onClick={ModuleUtils.gotoPage.bind(this, data.href, this.props, true)}>
                        <i className="fa fa-angle-right"/>
                        {data.title}
                    </button>
                </li>
            );
        });
    }

    render() {
        let { content } = this.state;

        return (
            <>
                <div className="section-container">
                    <section>
                        <div id="latest-blog">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-section">
                                            <h2>{content.header1}</h2>
                                            <img alt="" src="images/under-heading2.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-columns">
                                    {this.buildCards()}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div id="testimonials">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-section">
                                            <h2>{content.header2}</h2>
                                            <img alt="heading2" src="images/under-heading2.png" />
                                        </div>
                                    </div>
                                </div>

                                <MDBContainer className="text-size-container">
                                    <MDBCarousel activeItem={1} length={6} 
                                        showControls={false} 
                                        showIndicators={false} 
                                        className="z-depth-1 slides">
                                        <MDBCarouselInner>
                                            {this.buildTestimonials()}
                                        </MDBCarouselInner>
                                    </MDBCarousel>
                                </MDBContainer>
                            </div>
                        </div>
                    </section>
                </div>
                <div>
                    <footer>
                        <div className="container">
                            <div className="top-footer">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="subscribe-form">
                                            {content.newsLetter}
                                            <form  onSubmit={this.handleFormSubmit} className="subscribeForm" method="POST">
                                                <input id="email" maxLength={30} placeholder="Email" name="email" size={40} type="email" value={this.state.email} onChange={this.emailChange}/> 
                                                <input  id="submitButton" type="submit" value="Submeter consulta"/>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="social-bottom">
                                            <h4>{content.socialWeb}</h4>
                                            <a className="fa fa-facebook" href="https://www.facebook.com/pintorespinturadeinteriores" rel="noopener noreferrer" target="_blank"> </a> 
                                            <a className="fa fa-twitter" href="https://twitter.com/Pintorespaint4u" rel="noopener noreferrer" target="_blank"> </a> 
                                            <a className="fa fa-google-plus" href="https://plus.google.com/b/107313983256579920799/107313983256579920799" rel="noopener noreferrer" target="_blank"> </a> 
                                            <a className="fa fa-youtube-play" href="https://www.youtube.com/channel/UCOBbWfIfrtVxGXaJND93LUQ" rel="noopener noreferrer" target="_blank"> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div className="main-footer">
                                <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                        <div className="shop-list">
                                            <h4 className="footer-title">{content.partners.title}</h4>
                                            <ul>
                                                <li>
                                                    <a href="http://www.prime-clean.com" title="empresa de limpezas" rel="noopener noreferrer" target="_blank">
                                                        <i className="fa fa-users"/>
                                                        {content.partners.primeClean}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="http://www.robbialac.pt/" rel="noopener noreferrer" target="_blank">
                                                        <i className="fa fa-users"/>
                                                        {content.partners.robbialac}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="http://www.cin.pt/" rel="noopener noreferrer" target="_blank">
                                                        <i className="fa fa-users"/>
                                                        {content.partners.cin}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="http://www.karcher.pt/" rel="noopener noreferrer" target="_blank">
                                                        <i className="fa fa-users"/>
                                                        {content.partners.karcher}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-md-3 col-sm-6">
                                        <div className="shop-list">
                                            <h4 className="footer-title">
                                                {content.servicesTitle}
                                            </h4>
                                            <ul>
                                                {this.buildServices()}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-md-3 col-sm-6">
                                        <div className="recent-posts">
                                            <h4 className="footer-title">
                                                {content.workTitle}
                                            </h4>
                                            <div className="recent-post">
                                                <div className="recent-news-container">
                                                    <div className="recent-post-thumb">
                                                        <button onClick={ModuleUtils.gotoPage.bind(this, content.notify.href, this.props, true)}>
                                                            <img alt="notícias paint4u" src="images/noticias-paint4u.png" />
                                                        </button>
                                                    </div>
                                                    <div className="recent-post-info">
                                                        <h6> </h6>
                                                        <h6>
                                                            <button onClick={ModuleUtils.gotoPage.bind(this, content.notify.href, this.props, true)}>
                                                                {content.notify.title}
                                                            </button>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="recent-blog-container">
                                                    <div className="recent-post-thumb">
                                                        <button onClick={ModuleUtils.gotoPage.bind(this, content.blog.href, this.props, true)}>
                                                            <img alt="blog paint4u" src="images/blog-paint4u.png" />
                                                        </button>
                                                    </div>
                                                    <div className="recent-post-info">
                                                        <h6> </h6>
                                                        <h6>
                                                            <button onClick={ModuleUtils.gotoPage.bind(this, content.blog.href, this.props, true)}>
                                                                {content.blog.title}
                                                            </button>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="more-info">
                                            <h4 className="footer-title">{content.contacts.title}</h4>
                                            <p>
                                                {content.contacts.paragraph}
                                            </p>
                                            <ul>
                                                <li>
                                                    <i className="fa fa-phone"/>
                                                    {content.contacts.phone.num1}
                                                </li>
                                                <li>
                                                    <i className="fa fa-phone"/>
                                                    {content.contacts.phone.num2}
                                                </li>
                                                <li>
                                                    <i className="fa fa-globe"/>
                                                    {content.contacts.address}
                                                </li>
                                                <li>
                                                    <button onClick={ModuleUtils.gotoPage.bind(this, content.contacts.href, this.props, true)}>
                                                        <i className="fa fa-envelope"/>
                                                        {content.contacts.email}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-footer">
                                <p>{content.bottom.paragraph}
                                    <button onClick={ModuleUtils.gotoPage.bind(this, content.bottom.href, this.props, true)} title={content.bottom.title}>
                                       {content.bottom.text}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </footer>
                </div>
                {this.renderAlert()}
            </>
        );
    }
}

export const Footer = withRouter(FooterComp);