import React from "react";
import './ScrollTop.scss';

interface ScrollTopProps {

}

interface ScrollTopState {
    visible: boolean
}

export class ScrollTop extends React.Component<ScrollTopProps, ScrollTopState> {

    constructor(props: ScrollTopProps) {
        super(props);
        this.state = {
          visible: false
        };
    }

      componentDidMount() {
        document.addEventListener("scroll", (event: any) => {
          this.toggleVisibility();
        });
      }

      toggleVisibility = () => {
        let visibility: boolean = false;
        if (window.pageYOffset > 300) {
            visibility = true;
        } 
        this.setState({visible: visibility});
      }
    
      scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    
      render() {
        const { visible } = this.state;
        return (
          <div className="scroll-to-top">
            {visible && (
              <div onClick={this.scrollToTop}>
                <svg className="svg-scroll"
                  xmlns="http://www.w3.org/2000/svg"
                  height="38.735"
                  width="33.749"
                >
                  <g transform="translate(-18.121 -3.364)">
                    <rect className="svg-rect"
                      ry="4.928"
                      y="3.364"
                      x="18.121"
                      height="38.735"
                      width="33.749"
                      fill="#4285f4"
                    />
                    <g transform="translate(-.48 2.134)">
                      <rect
                        ry="4.928"
                        y="1.229"
                        x="18.601"
                        height="38.735"
                        width="33.749"
                        fill="url(#b)"
                      />
                      <g fill="#ececec">
                        <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                        <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            )}
          </div>
        );
      }
}