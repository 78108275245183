import React from 'react';
import './PageNotFound.scss';

export class PageNotFound extends React.Component {

    render() {
        return (
            <div className="notfound-container">
                <img src="/page-not-found.gif" alt="PageNotFound"/>
            </div>
        );
    }
}