export const types = {
    HEADER_LANGUAGE: 'HEADER/LANGUAGE',
    CURRENT_ROUTE: 'WORKSPACE/CURRENT_ROUTE'
}

export const actions = {
    setLanguage: (language: string) => ({
        type: types.HEADER_LANGUAGE,
        payload: {
            header: {
                language: language
            }
        }
    }),
    setCurrentRoute: (currentRoute: any) => ({
        type: types.CURRENT_ROUTE,
        payload: {
            currentRoute: currentRoute
        }
    })
}