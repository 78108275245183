import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
    MDBNavbar, MDBNavbarNav, MDBNavItem, MDBCollapse,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBNavbarToggler
} from "mdbreact";
import './Menu.scss';
import { withRouter } from 'react-router-dom';
import { MenuUtils } from './utils/menuUtils';
import { ModuleUtils } from 'utils/moduleUtils';
import { store } from 'modules/app/store';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

interface MenuProps {
    history: any,
    location: any,
    match: any
}

interface MenuState {
    isOpen: boolean,
    menu: any[],
    carousel: any[],
    pageLanguage: string
}

export class MenuComp extends React.Component<MenuProps, MenuState> {

    constructor(props: MenuProps) {
        super(props);
        this.state = {
          isOpen: false,
          pageLanguage: ModuleUtils.getLanguage(),
          menu: MenuUtils.buildMenu(),
          carousel: MenuUtils.buildCarousel(),

        }
    }
    
    componentDidUpdate() {
        let { pageLanguage } = this.state;
        let { language } = store.getState().workspace.header;
        if(language !== pageLanguage) {
            this.setState({
                    menu: MenuUtils.buildMenu(language),
                    carousel: MenuUtils.buildCarousel(language),
                    pageLanguage: language
                }
            );
        }
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    buildSlideShow = () => {
        let { carousel } = this.state;
        return carousel.map((data: any, index: number) => {
            return (
                <div key={index} className="each-fade" onClick={ModuleUtils.gotoPage.bind(this, data.href, this.props, false)}>
                    <img
                        className={data.image.className}
                        src={data.image.src}
                        alt={data.image.alt}
                    />
                </div>
            );
        });
    }

    buildMenu = () => {
        let { menu } = this.state;

        return menu.map((data: any, index: number) => {
            return (
                <MDBNavItem key={index}>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <span title={data.title} className="drop-item"
                                onClick={ModuleUtils.gotoPage.bind(this, data.main.href, this.props, false)}>
                                {data.main.text}
                            </span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            {this.buildDropDownItems(data.dropItems)}
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
            );
        });
    }

    buildDropDownItems = (dropItems: any) => {
        return dropItems.map((item: any, index: number) => {
            let { title, href, text } = item
            return (
                <MDBDropdownItem key={index}
                    onClick={ModuleUtils.gotoPage.bind(this, href, this.props, false)}
                    title={title}
                >
                    {text}
                </MDBDropdownItem>
            );
        })
    }

    render() {
        return (
            <div className="menu-container">
                <Router>
                    <MDBNavbar color="indigo" dark expand="md" className="navbar primary-color">
                    <MDBNavbarToggler onClick={this.toggleCollapse} />
                    <MDBCollapse className="navbar-collapse" id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav left>
                            {this.buildMenu()}
                        </MDBNavbarNav>
                    </MDBCollapse>
                    </MDBNavbar>
                </Router>
                <div className="slide-container">
                    <Fade>
                        {this.buildSlideShow()}
                    </Fade>
                </div>
            </div>
        );
    }
}

export const Menu = withRouter(MenuComp);