import React from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import Workspace from 'modules/workspace';
import { store } from '../store';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Loader } from 'modules/loader';
import 'font-awesome/css/font-awesome.min.css';

const LoadableHome = Loadable({
  loader: () => import('modules/home'),
  loading: Loader
});

const LoadablePageNotFound = Loadable({
  loader: () => import('modules/pagenotfound'),
  loading: Loader
});

const LoadablePaintBudget = Loadable({
  loader: () => import('modules/paintbudget'),
  loading: Loader
});

const LoadablePaintingBestPrice = Loadable({
  loader: () => import('modules/paintingbestprice'),
  loading: Loader
});

const LoadablePaintingAndCleaning = Loadable({
  loader: () => import('modules/paintingandcleaning'),
  loading: Loader
});

const LoadableClientTestimonials = Loadable({
  loader: () => import('modules/clienttestimonials'),
  loading: Loader
});

const LoadableWarranty = Loadable({
  loader: () => import('modules/warranty'),
  loading: Loader
});

const LoadablePaintingCompany = Loadable({
  loader: () => import('modules/paintingcompany'),
  loading: Loader
});

const LoadablePaintingCompanies = Loadable({
  loader: () => import('modules/paintingcompanies'),
  loading: Loader
});

const LoadablePaintingService = Loadable({
  loader: () => import('modules/paintingservice'),
  loading: Loader
});

const LoadablePaintingOneDay = Loadable({
    loader: () => import('modules/paintingoneday'),
    loading: Loader
});

const LoadableCompanyValues = Loadable({
    loader: () => import('modules/companyvalues'),
    loading: Loader
});

const LoadableCompanyHistory = Loadable({
    loader: () => import('modules/companyhistory'),
    loading: Loader
});

const LoadablePartnerships = Loadable({
    loader: () => import('modules/partnerships'),
    loading: Loader
});

const LoadablePainters = Loadable({
    loader: () => import('modules/painters'),
    loading: Loader
});

const LoadableTeamsOfPainters = Loadable({
    loader: () => import('modules/teamsofpainters'),
    loading: Loader
});

const LoadableProfessionalPainters = Loadable({
    loader: () => import('modules/professionalpainters'),
    loading: Loader
});

const LoadableQualityPainters = Loadable({
    loader: () => import('modules/qualitypainters'),
    loading: Loader
});

const LoadableRecruitmentPainters = Loadable({
    loader: () => import('modules/recruitmentpainters'),
    loading: Loader
});

const LoadableWorkConstructionPainter = Loadable({
    loader: () => import('modules/workconstructionpainter'),
    loading: Loader
});

const LoadableInteriorHousePainting = Loadable({
    loader: () => import('modules/interiorhousepainting'),
    loading: Loader
});

const LoadablePaintingMethod = Loadable({
    loader: () => import('modules/paintingmethod'),
    loading: Loader
});

const LoadableVillasHousePainting = Loadable({
    loader: () => import('modules/villashousepainting'),
    loading: Loader
});

const LoadablePaintingApartment = Loadable({
    loader: () => import('modules/paintingapartment'),
    loading: Loader
});

const LoadablePaintingRentedApartments = Loadable({
    loader: () => import('modules/paintingrentedapartments'),
    loading: Loader
});

const LoadableOfficePainting = Loadable({
    loader: () => import('modules/officepainting'),
    loading: Loader
});

const LoadablePaintingShops = Loadable({
    loader: () => import('modules/paintingshops'),
    loading: Loader
});

const LoadablePaintingWallsCeilings = Loadable({
    loader: () => import('modules/paintingwallsceilings'),
    loading: Loader
});

const LoadableRoofPainting = Loadable({
    loader: () => import('modules/roofpainting'),
    loading: Loader
});

const LoadableWoodPainting = Loadable({
    loader: () => import('modules/woodpainting'),
    loading: Loader
});

const LoadableMetalPainting = Loadable({
    loader: () => import('modules/metalpainting'),
    loading: Loader
});

const LoadablePressureWashHouse = Loadable({
    loader: () => import('modules/pressurewashhouse'),
    loading: Loader
});

const LoadableMoistureMold = Loadable({
    loader: () => import('modules/moisturemold'),
    loading: Loader
});

const LoadableMoistureDiagnosis = Loadable({
    loader: () => import('modules/moisturediagnosis'),
    loading: Loader
});

const LoadableRepairControlHumidity = Loadable({
    loader: () => import('modules/repaircontrolhumidity'),
    loading: Loader
});

const LoadablePaintingMoldRemoval = Loadable({
    loader: () => import('modules/paintingmoldremoval'),
    loading: Loader
});

const LoadablePaintingAntiFungal = Loadable({
    loader: () => import('modules/paintingantifungal'),
    loading: Loader
});

const LoadableSaltPeterCausesEffects = Loadable({
    loader: () => import('modules/saltpetercauseseffects'),
    loading: Loader
});

const LoadableSaltPeterMoistureDiagnosis = Loadable({
    loader: () => import('modules/saltpetermoisturediagnosis'),
    loading: Loader
});

const LoadableMoistureTreatment = Loadable({
    loader: () => import('modules/moisturetreatment'),
    loading: Loader
});

const LoadableSaltPeterTreatment = Loadable({
    loader: () => import('modules/saltpetertreatment'),
    loading: Loader
});

const LoadablePostFireRestoration = Loadable({
    loader: () => import('modules/postfirerestoration'),
    loading: Loader
});

const LoadableRepairWallsCeilings = Loadable({
    loader: () => import('modules/repairwallsceilings'),
    loading: Loader
});

const LoadableImportancePrimingPainting = Loadable({
    loader: () => import('modules/importanceprimingpainting'),
    loading: Loader
});

const LoadableFinalPaintingOfSurfaces = Loadable({
    loader: () => import('modules/finalpaintingofsurfaces'),
    loading: Loader
});

const LoadableSafetyPaintings = Loadable({
    loader: () => import('modules/safetypaintings'),
    loading: Loader
});

const LoadableQualityPaintings = Loadable({
    loader: () => import('modules/qualitypaintings'),
    loading: Loader
});

const LoadableEcologicalPainting = Loadable({
    loader: () => import('modules/ecologicalpainting'),
    loading: Loader
});

const LoadablePaintingWithQuality = Loadable({
    loader: () => import('modules/paintingwithquality'),
    loading: Loader
});

const LoadableConfirmSubscription = Loadable({
    loader: () => import('modules/confirmsubscription'),
    loading: Loader
});

const LoadableConfirmPaintBudget = Loadable({
    loader: () => import('modules/confirmpaintbudget'),
    loading: Loader
});

const LoadableTechnicalFiles = Loadable({
    loader: () => import('modules/technicalfiles'),
    loading: Loader
});

export class App extends React.Component {

  render() {
    return (
   <BrowserRouter>
      <Provider store={store}>
        <Workspace>
            <Switch>
                <Redirect exact from="/" to="/inicio"/>
                {/* Done */}
                <Route exact path="/inicio" component={LoadableHome}/>
                <Route exact path="/home" component={LoadableHome}/>
                {/* Done */}
                <Route exact path="/orcamento-pinturas" component={LoadablePaintBudget}/>
                <Route exact path="/paint-budget" component={LoadablePaintBudget}/>
                {/* Done */}
                <Route exact path="/pinturas-pintores-melhor-preco" component={LoadablePaintingBestPrice}/>
                <Route exact path="/paintings-best-price" component={LoadablePaintingBestPrice}/>
                {/* Done */}
                <Route exact path="/pinturas-com-limpeza" component={LoadablePaintingAndCleaning}/>
                <Route exact path="/painting-and-cleaning" component={LoadablePaintingAndCleaning}/>
                {/* Done */}
                <Route exact path="/empresa-pinturas" component={LoadablePaintingCompany}/>
                <Route exact path="/painting-company" component={LoadablePaintingCompany}/>
                {/* Done */}
                <Route exact path="/empresas-pintura" component={LoadablePaintingCompanies}/>
                <Route exact path="/painting-companies" component={LoadablePaintingCompanies}/>
                {/* Done */}
                <Route exact path="/servico-paint4u" component={LoadablePaintingService}/>
                <Route exact path="/paintings-service-paint4u" component={LoadablePaintingService}/>
                {/* Done */}
                <Route exact path="/pintura-rapida" component={LoadablePaintingOneDay}/>
                <Route exact path="/house-painting-in-one-day" component={LoadablePaintingOneDay}/>
                {/* Done */}
                <Route exact path="/valores-paint4u" component={LoadableCompanyValues}/>
                <Route exact path="/values-paint4u" component={LoadableCompanyValues}/>
                {/* Done */}
                <Route exact path="/historia-paint4u" component={LoadableCompanyHistory}/>
                <Route exact path="/paint4u-history" component={LoadableCompanyHistory}/>
                {/* Done */}
                <Route exact path="/parceiros-paint4u" component={LoadablePartnerships}/>
                <Route exact path="/paint4u-partnerships" component={LoadablePartnerships}/>
                {/* Done */}
                <Route exact path="/pintores" component={LoadablePainters}/>
                <Route exact path="/painters" component={LoadablePainters}/>
                {/* Done */}
                <Route exact path="/equipas-pintores" component={LoadableTeamsOfPainters}/>
                <Route exact path="/teams-of-painters" component={LoadableTeamsOfPainters}/>
                {/* Done */}
                <Route exact path="/pintores-profissionais" component={LoadableProfessionalPainters}/>
                <Route exact path="/professional-painters" component={LoadableProfessionalPainters}/>
                {/* Done */}
                <Route exact path="/pintores-de-qualidade" component={LoadableQualityPainters}/>
                <Route exact path="/quality-painters" component={LoadableQualityPainters}/>
                {/* Done */}
                <Route exact path="/recrutamento-selecao-pintores" component={LoadableRecruitmentPainters}/>
                <Route exact path="/recruitment-selection-painters" component={LoadableRecruitmentPainters}/>
                {/* Done */}
                <Route exact path="/vagas-pintores-paint4u" component={LoadableWorkConstructionPainter}/>
                <Route exact path="/work-construction-painter" component={LoadableWorkConstructionPainter}/>
                {/* Done */}
                <Route exact path="/pintura-interiores" component={LoadableInteriorHousePainting}/>
                <Route exact path="/interior-house-painting" component={LoadableInteriorHousePainting}/>
                {/* Done */}
                <Route exact path="/metodo-pintura" component={LoadablePaintingMethod}/>
                <Route exact path="/painting-method" component={LoadablePaintingMethod}/>
                {/* Done */}
                <Route exact path="/pintura-moradias" component={LoadableVillasHousePainting}/>
                <Route exact path="/villas-houses-painting" component={LoadableVillasHousePainting}/>
                {/* Done */}
                <Route exact path="/pintura-apartamentos" component={LoadablePaintingApartment}/>
                <Route exact path="/apartment-painting-painters" component={LoadablePaintingApartment}/>
                {/* Done */}
                <Route exact path="/pintura-casas-turisticas" component={LoadablePaintingRentedApartments}/>
                <Route exact path="/painting-rented-apartments-turism-places" component={LoadablePaintingRentedApartments}/>
                {/* Done */}
                <Route exact path="/pintura-escritorios" component={LoadableOfficePainting}/>
                <Route exact path="/office-painting" component={LoadableOfficePainting}/>
                {/* Done */}
                <Route exact path="/pintura-lojas" component={LoadablePaintingShops}/>
                <Route exact path="/shops-establishments-painting" component={LoadablePaintingShops}/>
                {/* /pintura-superficies - não existe fica sem link
                    /paiting-of-surfaces */}

                {/* /recomendacao-cores-pintura - nam existe componente...
                    /paint-color-recomendation */}

                {/* Done */}
                <Route exact path="/pintura-paredes-tetos" component={LoadablePaintingWallsCeilings}/>
                <Route exact path="/walls-ceilings-painting" component={LoadablePaintingWallsCeilings}/>
                {/* Done */}
                <Route exact path="/pintura-telhados" component={LoadableRoofPainting}/>
                <Route exact path="/roof-painting" component={LoadableRoofPainting}/>
                {/* Done */}
                <Route exact path="/pintura-madeiras" component={LoadableWoodPainting}/>
                <Route exact path="/wood-painting" component={LoadableWoodPainting}/>
                {/* Done */}
                <Route exact path="/pintura-metais" component={LoadableMetalPainting}/>
                <Route exact path="/metal-painting" component={LoadableMetalPainting}/>
                {/* Done */}
                <Route exact path="/lavagem-pressao-pintar" component={LoadablePressureWashHouse}/>
                <Route exact path="/pressure-wash-house" component={LoadablePressureWashHouse}/>
                {/* Done */}
                <Route exact path="/humidades" component={LoadableMoistureMold}/>
                <Route exact path="/moisture-mould-remediation" component={LoadableMoistureMold}/>
                {/* Done */}
                <Route exact path="/diagnostico-humidades" component={LoadableMoistureDiagnosis}/>
                <Route exact path="/moisture-diagnosis" component={LoadableMoistureDiagnosis}/>
                {/* Done */}
                <Route exact path="/reparacao-humidades" component={LoadableRepairControlHumidity}/>
                <Route exact path="/repair-control-humidity" component={LoadableRepairControlHumidity}/>
                {/* Done */}
                <Route exact path="/remocao-bolores" component={LoadablePaintingMoldRemoval}/>
                <Route exact path="/painting-mold-removal" component={LoadablePaintingMoldRemoval}/>
                {/* Done */}
                <Route exact path="/pintura-antifungos" component={LoadablePaintingAntiFungal}/>
                <Route exact path="/anti-fungal-painting" component={LoadablePaintingAntiFungal}/>
                {/* Done */}
                <Route exact path="/salitre" component={LoadableSaltPeterCausesEffects}/>
                <Route exact path="/saltpeter-causes-effects" component={LoadableSaltPeterCausesEffects}/>
                {/* Done */}
                <Route exact path="/diagnostico-salitre" component={LoadableSaltPeterMoistureDiagnosis}/>
                <Route exact path="/saltpeter-moisture-diagnosis" component={LoadableSaltPeterMoistureDiagnosis}/>
                {/* Done */}
                <Route exact path="/humidade-capilaridade" component={LoadableMoistureTreatment}/>
                <Route exact path="/moisture-capillary-treatment" component={LoadableMoistureTreatment}/>
                {/* Done */}
                <Route exact path="/tratamento-salitre" component={LoadableSaltPeterTreatment}/>
                <Route exact path="/treatment-repair-saltpeter" component={LoadableSaltPeterTreatment}/>
                {/* Done */}
                <Route exact path="/pintura-pos-incendios" component={LoadablePostFireRestoration}/>
                <Route exact path="/fire-smoke-damage-restoration" component={LoadablePostFireRestoration}/>
                {/* Done */}
                <Route exact path="/reparacao-tetos-paredes-pintura" component={LoadableRepairWallsCeilings}/>
                <Route exact path="/walls-ceilings-repair-painting" component={LoadableRepairWallsCeilings}/>
                {/* Done */}
                <Route exact path="/aplicacao-primarios-pinturas" component={LoadableImportancePrimingPainting}/>
                <Route exact path="/importance-priming-painting" component={LoadableImportancePrimingPainting}/>
                {/* Done */}
                <Route exact path="/pintura-final-superficies" component={LoadableFinalPaintingOfSurfaces}/>
                <Route exact path="/final-paiting-of-surfaces" component={LoadableFinalPaintingOfSurfaces}/>
                {/* Done */}
                <Route exact path="/garantia-paint4u" component={LoadableWarranty}/>
                <Route exact path="/paint4u-warranty" component={LoadableWarranty}/>
                {/* Done */}
                <Route exact path="/seguranca-trabalhar-paint4u" component={LoadableSafetyPaintings}/>
                <Route exact path="/safely-paintings" component={LoadableSafetyPaintings}/>
                {/* Done */}
                <Route exact path="/qualidade-paint4u" component={LoadableQualityPaintings}/>
                <Route exact path="/quality-paintings" component={LoadableQualityPaintings}/>
                {/* Done */}
                <Route exact path="/pintura-ecologica" component={LoadableEcologicalPainting}/>
                <Route exact path="/ecological-painting" component={LoadableEcologicalPainting}/>
                {/* Done */}
                <Route exact path="/tintas-de-qualidade" component={LoadablePaintingWithQuality}/>
                <Route exact path="/paintings-with-quality-paint" component={LoadablePaintingWithQuality}/>
                {/* Done */}
                <Route exact path="/testemunhos-de-clientes" component={LoadableClientTestimonials}/>
                <Route exact path="/client-testimonials" component={LoadableClientTestimonials}/>
                {/* Done */}
                <Route exact path="/confirma-subscricao" component={LoadableConfirmSubscription}/>
                <Route exact path="/confirm-subscription" component={LoadableConfirmSubscription}/>
                {/* Done */}
                <Route exact path="/confirma-orcamentos-pinturas" component={LoadableConfirmPaintBudget}/>
                <Route exact path="/confirm-paint-budget" component={LoadableConfirmPaintBudget}/>
                {/* Done */}
                <Route exact path="/fichas-tecnicas" component={LoadableTechnicalFiles}/>
                <Route exact path="/technical-files" component={LoadableTechnicalFiles}/>

                <Route path='*' component={LoadablePageNotFound}/>
            </Switch>
        </Workspace>
      </Provider>
    </BrowserRouter>
    );
  }
}