import { ModuleUtils } from "utils";

export class MenuUtils {

    static buildMenu(language?: string) {
        let content: any = {
            pt: [
                {
                    title: "empresa de pinturas Paint4U",
                    main: {
                        href: "/empresa-pinturas",
                        text: "A Empresa",
                    },
                    dropItems: [
                        {
                            href: "/empresas-pintura",
                            title: "Empresas de pinturas",
                            text: "Porquê Paint4U"
                        },
                        {
                            href: "/servico-paint4u",
                            title: "Serviço pintores profissionais",
                            text: "Como Funciona"
                        },
                        {
                            href: "/pintura-rapida",
                            title: "pintura em um dia",
                            text: "Pintura em 1 dia!"
                        },
                        {
                            href: "/valores-paint4u",
                            title: "Valores paint4u",
                            text: "Valores"
                        },
                        {
                            href: "/historia-paint4u",
                            title: "História pintores Paint4U",
                            text: "História"
                        },
                        {
                            href: "/parceiros-paint4u",
                            title: "parceiros pinturas",
                            text: "Parcerias"
                        }
                    ]
                },
                {
                    title: "pintores",
                    main: {
                        href: "/pintores",
                        text: "Os Pintores"
                    },
                    dropItems: [
                        {
                            href: "/equipas-pintores",
                            title: "equipas pintores profissionais",
                            text: "Equipas Pintores"
                        },
                        {
                            href: "/pintores-profissionais",
                            title: "pintores profissionais",
                            text: "Pintores Profissionais"
                        },
                        {
                            href: "/pintores-de-qualidade",
                            title: "pintores de qualidade",
                            text: "Os melhores Pintores"
                        },
                        {
                            href: "/recrutamento-selecao-pintores",
                            title: "recrutamento e seleção de pintores",
                            text: "Recrutamento e Seleção" 
                        },
                        {
                            href: "/vagas-pintores-paint4u",
                            title: "vagas para pintores paint4u",
                            text: "Junte-se à nossa equipa"
                        }
                    ]
                },
                {   
                    title: "pinturas de interiores",
                    main: {
                        href: "/pintura-interiores",
                        text: "Interiores"
                    },
                    dropItems: [
                        {
                            href: "/metodo-pintura",
                            title: "metodo de trabalho paint4u",
                            text: "Método Paint4U"
                        },
                        {
                            href: "/pintura-moradias",
                            title: "pintura de moradias",
                            text: "Moradias"
                        },
                        {
                            href: "/pintura-apartamentos",
                            title: "pintura de apartamentos",
                            text: "Apartamentos"
                        },
                        {
                            href: "/pintura-casas-turisticas",
                            title: "pintura de casas turisticas",
                            text: "Casas turísticas"
                        },
                        {
                            href: "/pintura-escritorios",
                            title: "pintura de escritórios",
                            text: "Pintura escritórios"
                        },
                        {
                            href: "/pintura-lojas",
                            title: "pintura de lojas e espaços comerciais",
                            text: "Pintura lojas"
                        }
                    ]
                },
                {
                    title: "superfícies",
                    main: {
                        // href: "#",
                        text: "Superfícies"
                    },
                    dropItems: [
                        {
                            href: "/recomendacao-cores-pintura",
                            title: "recomendação de cores",
                            text: "Cores"
                        },
                        {
                            href: "/pintura-paredes-tetos",
                            title: "metodo pintura de exteriores",
                            text: "Paredes e Tetos"
                        },
                        {
                            href: "/pintura-telhados",
                            title: "pintura de telhados",
                            text: "Pintura de Telhados"
                        },
                        {
                            href: "/pintura-madeiras",
                            title: "pintura de madeiras",
                            text: "Pintura de Madeiras"
                        },
                        {
                            href: "/pintura-metais",
                            title: "pintura de metais",
                            text: "Pintura de Metais"
                        },
                        {
                            href: "/lavagem-pressao-pintar",
                            title: "lavagem à pressão para pintar",
                            text: "Lavagem à Pressão"
                        }
                    ]
                },
                {
                    title: "humidades",
                    main: {
                        href: "/humidades",
                        text: "Humidades"
                    },
                    dropItems: [
                        {
                            href: "/diagnostico-humidades",
                            title: "diagnostico de humidades",
                            text: "Diagnóstico"
                        },
                        {
                            href: "/reparacao-humidades",
                            title: "reparação de infiltrações",
                            text: "Reparação de Humidades"
                        },
                        {
                            href: "/remocao-bolores",
                            title: "remoção de bolores",
                            text: "Limpeza de Bolores"
                        },
                        {
                            href: "/pintura-antifungos",
                            title: "pintura antifungos",
                            text: "Pintura anti-fungos"
                        }
                    ]
                },
                {
                    title: "salitre",
                    main: {
                        href: "/salitre",
                        text: "Salitre"
                    },
                    dropItems: [
                        {
                            href: "/diagnostico-salitre",
                            title: "diagnostico de salitres",
                            text: "Diagnóstico"
                        },
                        {
                            href: "/humidade-capilaridade",
                            title: "humidade por capilaridade",
                            text: "Humidade por Capilaridade"
                        },
                        {
                            href: "/tratamento-salitre",
                            title: "tratamento de salitres",
                            text: "Tratamento Salitre"
                        }
                    ]
                },
                {
                    title: "pinturas e reparações pós incêndios",
                    main: {
                        href: "/pintura-pos-incendios",
                        text: "Pós-incêndios"
                    },
                    dropItems: [
                        {
                            href: "/reparacao-tetos-paredes-pintura",
                            title: "reparação de tetos",
                            text: "Reparação Tetos Paredes"
                        },
                        {
                            href: "/aplicacao-primarios-pinturas",
                            title: "aplicação de primários",
                            text: "Aplicação de Primários"
                        },
                        {
                            href: "/pintura-final-superficies",
                            title: "pintura final de superficies",
                            text: "Pintura de Superficies"
                        }
                    ]
                },
                {
                    title: "garantias",
                    main: {
                        // href: "#",
                        text: "Garantias"
                    },
                    dropItems: [
                        {
                            href: "/garantia-paint4u",
                            title: "garantia de trabalhos paint4u",
                            text: "Garantia Paint4U"
                        },
                        {
                            href: "/seguranca-trabalhar-paint4u",
                            title: "segurança ao trabalhar com a Paint4u",
                            text: "Segurança"
                        },
                        {
                            href: "/qualidade-paint4u",
                            title: "qualidade da pintura paint4u",
                            text: "Qualidade"
                        },
                        {
                            href: "/pintura-ecologica",
                            title: "pintura ecológica",
                            text: "Pintura Ecológica"
                        },
                        {
                            href: "/tintas-de-qualidade",
                            title: "tintas de qualidade",
                            text: "Tintas de Qualidade"
                        },
                        {
                            href: "/testemunhos-de-clientes",
                            title: "testemunhos dos clientes",
                            text: "Testemunhos"
                        },
                        {
                            href: "/fichas-tecnicas",
                            title: "fichas técnicas",
                            text: "Fichas Técnicas"
                        }
                    ]
                }
            ],
            en: [
                {
                    title: "paintings company Paint4U",
                    main: {
                        href: "/painting-company",
                        text: "The Company",
                    },
                    dropItems: [
                        {
                            href: "/painting-companies",
                            title: "Painting companies",
                            text: "Why Paint4U"
                        },
                        {
                            href: "/paintings-service-paint4u",
                            title: "paintings service",
                            text: "How It Works"
                        },
                        {
                            href: "/house-painting-in-one-day",
                            title: "painting in one day",
                            text: "Painting in 1 day!"
                        },
                        {
                            href: "/values-paint4u",
                            title: "Paint4u Values",
                            text: "Values"
                        },
                        {
                            href: "/paint4u-history",
                            title: "Paint4U History",
                            text: "History"
                        },
                        {
                            href: "/paint4u-partnerships",
                            title: "painting partners",
                            text: "Partnerships"
                        }
                    ]
                },
                {
                    title: "painters",
                    main: {
                        href: "/painters",
                        text: "Painters"
                    },
                    dropItems: [
                        {
                            href: "/teams-of-painters",
                            title: "painters team",
                            text: "Painters Team"
                        },
                        {
                            href: "/professional-painters",
                            title: "Professional painters teams",
                            text: "Professional painters"
                        },
                        {
                            href: "/quality-painters",
                            title: "quality painters",
                            text: "Best painters"
                        },
                        {
                            href: "/recruitment-selection-painters",
                            title: "recruitment and selection of painters",
                            text: "Recruitment and selection" 
                        },
                        {
                            href: "/work-construction-painter",
                            title: "paint4u painters spaces",
                            text: "Join our team"
                        }
                    ]
                },
                {   
                    title: "interior painting",
                    main: {
                        href: "/interior-house-painting",
                        text: "Interior paint"
                    },
                    dropItems: [
                        {
                            href: "/painting-method",
                            title: "Paint4u working method",
                            text: "Paint4U method"
                        },
                        {
                            href: "/villas-houses-painting",
                            title: "house painting",
                            text: "House Painting"
                        },
                        {
                            href: "/apartment-painting-painters",
                            title: "apartments painting",
                            text: "Apartments"
                        },
                        {
                            href: "/painting-rented-apartments-turism-places",
                            title: "touristic houses painting",
                            text: "Turistic painting"
                        },
                        {
                            href: "/office-painting",
                            title: "office painting",
                            text: "Office painting"
                        },
                        {
                            href: "/shops-establishments-painting",
                            title: "shops and commercial spaces painting",
                            text: "Shops painting"
                        }
                    ]
                },
                {
                    title: "surfaces",
                    main: {
                        // href: "#",
                        text: "Surfaces"
                    },
                    dropItems: [
                        {
                            href: "/paint-color-recomendation",
                            title: "color recomendation",
                            text: "Colors"
                        },
                        {
                            href: "/walls-ceilings-painting",
                            title: "ceilings and walls apinting",
                            text: "Ceilings and Walls"
                        },
                        {
                            href: "/roof-painting",
                            title: "Roofs painting",
                            text: "Roofs painting"
                        },
                        {
                            href: "/wood-painting",
                            title: "wood painting",
                            text: "Wood painting"
                        },
                        {
                            href: "/metal-painting",
                            title: "metals painting",
                            text: "Metals painting"
                        },
                        {
                            href: "/pressure-wash-house",
                            title: "wash pressure to paint",
                            text: "Pressure wash"
                        }
                    ]
                },
                {
                    title: "moisture",
                    main: {
                        href: "/moisture-mould-remediation",
                        text: "Moisture"
                    },
                    dropItems: [
                        {
                            href: "/moisture-diagnosis",
                            title: "moisture diagnostic",
                            text: "Diagnostic"
                        },
                        {
                            href: "/repair-control-humidity",
                            title: "leaks repair",
                            text: "Moisture repair"
                        },
                        {
                            href: "/painting-mold-removal",
                            title: "Mold remove",
                            text: "Mold remove"
                        },
                        {
                            href: "/anti-fungal-painting",
                            title: "antifungal painting",
                            text: "Antifungal Painting"
                        }
                    ]
                },
                {
                    title: "Saltpeter",
                    main: {
                        href: "/saltpeter-causes-effects",
                        text: "Saltpeter"
                    },
                    dropItems: [
                        {
                            href: "/saltpeter-moisture-diagnosis",
                            title: "saltpeter diagnostic",
                            text: "Diagnostic"
                        },
                        {
                            href: "/moisture-capillary-treatment",
                            title: "capillary moisture",
                            text: "Capillary Moisture"
                        },
                        {
                            href: "/treatment-repair-saltpeter",
                            title: "saltpeter treatment",
                            text: "Saltpeter treatment"
                        }
                    ]
                },
                {
                    title: "after fire painting and repairs",
                    main: {
                        href: "/fire-smoke-damage-restoration",
                        text: "After fire"
                    },
                    dropItems: [
                        {
                            href: "/walls-ceilings-repair-painting",
                            title: "ceilings repair",
                            text: "Ceilings and walls repair"
                        },
                        {
                            href: "/importance-priming-painting",
                            title: "Primer aplication",
                            text: "Primer aplication"
                        },
                        {
                            href: "/paiting-of-surfaces",
                            title: "surfaces final painting",
                            text: "Surfaces painting"
                        }
                    ]
                },
                {
                    title: "warranty",
                    main: {
                        // href: "#",
                        text: "Warranty"
                    },
                    dropItems: [
                        {
                            href: "/painting-warranty",
                            title: "paint4U warranties",
                            text: "Paint4u warranty"
                        },
                        {
                            href: "/safely-paintings",
                            title: "safety when working with the Paint4u",
                            text: "Safety"
                        },
                        {
                            href: "/quality-paintings",
                            title: "paint4u quality",
                            text: "Quality"
                        },
                        {
                            href: "/ecological-painting",
                            title: "ecological paint",
                            text: "Ecological paint"
                        },
                        {
                            href: "/paintings-with-quality-paint",
                            title: "quality paints",
                            text: "Quality paints"
                        },
                        {
                            href: "/client-testimonials",
                            title: "customer testimonials",
                            text: "Testimonials"
                        },
                        {
                            href: "/technical-files",
                            title: "technical files",
                            text: "Technical Files"
                        }
                    ]
                }
            ]
        };
        return content[!language ? ModuleUtils.getLanguage() : language]; 
    }

    static buildCarousel(language?: string) {
        let content: any = {
            pt: [
                {
                    id: "1",
                    href: "/equipas-pintores",
                    image: {
                        alt: "pintores de construção",
                        src: "/images/pintores-paint4u.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "2",
                    href: "/pintura-moradias",
                    image: {
                        alt: "pintura de casas e moradias",
                        src: "/images/pinturas-interiores.jpg",
                        className: "d-block-2 w-100"
                    }
                },
                {
                    id: "3",
                    href: "/pintores-profissionais",
                    image: {
                        alt: "pintores profissionais",
                        src: "/images/pintores-profissionais.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "4",
                    href: "/pintura-interiores",
                    image: {
                        alt: "pintura de salas",
                        src: "/images/pintura-salas.jpg",
                        className: "d-block-2 w-100"
                    }
                },
                {
                    id: "5",
                    href: "/servico-paint4u",
                    image: {
                        alt: "pinturas cozinhas",
                        src: "/images/pintura-cozinhas.jpg",
                        className: "d-block-2 w-100"
                    }
                },
                {
                    id: "6",
                    href: "/pintura-paredes-tetos",
                    image: {
                        alt: "pintura tetos paredes",
                        src: "/images/pintura-tetos-paredes.jpg",
                        className: "d-block-1 w-100"
                    }
                }
            ],
            en: [
                {
                    id: "1",
                    href: "/teams-of-painters",
                    image: {
                        alt: "building painters",
                        src: "/images/building-painters.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "2",
                    href: "/villas-houses-painting",
                    image: {
                        alt: "painting of houses and villas",
                        src: "/images/interior-paintings.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "3",
                    href: "/professional-painters",
                    image: {
                        alt: "professional painters",
                        src: "/images/professional-painters.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "4",
                    href: "/interior-house-painting",
                    image: {
                        alt: "rooms painting",
                        src: "/images/rooms-painting.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "5",
                    href: "/paintings-service-paint4u",
                    image: {
                        alt: "kitchen paint",
                        src: "/images/kitchen-painting.jpg",
                        className: "d-block-1 w-100"
                    }
                },
                {
                    id: "6",
                    href: "/walls-ceilings-repair-painting",
                    image: {
                        alt: "painting walls and ceilings",
                        src: "/images/painting-walls-ceilings.jpg",
                        className: "d-block-1 w-100"
                    }
                }
            ]
        };
        return content[!language ? ModuleUtils.getLanguage() : language]; 
    }
}