import { ModuleUtils } from "utils";

export class FooterUtils {

    static buildHomeCards(language?: string) {
        let content: any = {
            pt: [
                {
                    image: {
                        alt: "Pintura de Interiores",
                        src: "/images/pinturas-em-seguranca.jpg",
                        title: "Pintura de Interiores"
                    },
                    content: {
                        href: "/pintura-interiores",
                        title: "Pinturas de Interiores",
                        intro: "A PAINT4U é especializada em pinturas de interiores.",
                        resume: `Os nossos pintores são treinados no método de pintura da marca e garantem um serviço 
                                de primeira qualidade. Para aumentar a sua satisfação, o serviço é 
                                permanentemente supervisionado e verificado no final para garantir que tudo está impecável.`,
                        more: {
                            href: "/pintura-interiores",
                            text: "Saiba mais..."

                        },
                        expanded: false 
                    }
                },
                {
                    image: {
                        alt: "Pintura de Superficies",
                        src: "/images/pinturas-exteriores.png",
                        title: "Pintura de Superficies",
                        className: "card-image-position"
                    },
                    content: {
                        href: "/pintura-madeiras",
                        title: "Pinturas de superficíes",
                        intro: "As nossas equipas de pintores são especializadas na pintura das diversas superficíes de uma habitação.",
                        resume: "Pintamos todo o tipo de materiais de revestimento em paredes e tetos. Se pretende conhecer melhor o nosso serviço contacte-nos.",
                        more: {
                            href: "/pintura-madeiras",
                            text: "Saiba mais..."
                        },
                        expanded: false,
                        className: "card-2-content-size"
                    }
                },
                {
                    image: {
                        alt: "Pintura de paredes e tetos",
                        src: "/images/pinturas-tetos.jpg",
                        title: "Pintura de paredes e tetos"
                    },
                    content: {
                        href: "/pintura-paredes-tetos",
                        title: "Pintura de paredes e Tetos",
                        intro: "Os pintores da PAINT4U são especializados em pintura de interiores.",
                        resume: "Com as nossas equipas de pintores, a pintura dos tetos e paredes da sua habitação ficarão como novos. Se não sabe quais as melhores tintas a utilizar, os nossos técnicos terão todo o gosto em ajudar.",
                        more: {
                            href: "/pintura-paredes-tetos",
                            text: "Saiba mais..."
                        },
                        expanded: false,
                        className: "card-3-content-size" 
                    }
                },
                {
                    image: {
                        alt: "Pinturas de paredes",
                        src: "/images/pinturas-paredes.jpg",
                        title: "Pinturas de paredes"
                    },
                    content: {
                        href: "/pintura-casas-turisticas",
                        title: "Pintura de casas turisticas",
                        intro: "Se possui casas ou apartamentos turísticos para alugar sabe a importância da pintura na imagem que transmite aos seus clientes.",
                        resume: "O serviço de pinturas de apartamentos turisticos Paint4U foi pensado em garantir um serviço rápido, limpo e económico efetuado num unico dia. Contacte-nos.",
                        more: {
                            href: "/pintura-casas-turisticas",
                            text: "Saiba mais..."
                        },
                        expanded: false 
                    }
                },
                {
                    image: {
                        alt: "Pinturas de lojas",
                        src: "/images/pintura-lojas.jpg",
                        title: "Pinturas de lojas"
                    },
                    content: {
                        href: "/pintura-lojas",
                        title: "Pintura de lojas",
                        intro: "A renovação da decoração e a pintura do espaço confere muitas vezes aquele toque especial que o cliente procura.",
                        resume: "Com a Paint4U poderá ter a sua loja ou espaço comercial pintada de um dia para o outro sem contratempos. Efetuamos também pinturas durante horário noturno. Contacte-nos.",
                        more: {
                            href: "/pintura-lojas",
                            text: "Saiba mais..."
                        },
                        expanded: false,
                        className: "card-5-content-size"
                    }
                },
                {
                    image: {
                        alt: "Limpeza e pintura de bolores",
                        src: "/images/limpeza-pintura-bolores.jpg",
                        title: "Limpeza e pintura de bolores"
                    },
                    content: {
                        href: "/humidades",
                        title: "Recuperação de bolores e Salitre",
                        intro: "Os salitres e bolores provém de problemas associados com Humidades.",
                        resume: "As equipas de pintores profissionais da PAINT4U estão formadas para resolver muito mais que uma simples pintura. Os nossos técnicos efetuam um diagnóstico da proveniência da Humidade desenvolvendo a seu pedido a reparação e recuperação de todas as àreas afetadas.",
                        more: {
                            href: "/reparacao-humidades",
                            text: "Saiba mais..."
                        },
                        expanded: false,
                        className: "card-6-content-size"
                    }
                }
            ],
            en: [
                {
                    image: {
                        alt: "mold painting",
                        src: "/images/pinturas-em-seguranca.jpg",
                        title: "Interior Paintings"
                    },
                    content: {
                        href: "/interior-house-painting",
                        title: "Interior Paintings",
                        intro: "The PAINT4U specializes in interior paints.",
                        resume: `Our painters are trained in the paint method 
                                of the brand and ensure a first class service. 
                                To increase your satisfaction, the service is 
                                permanently supervised and verified at the end 
                                to make sure everything is spotless.`,
                        more: {
                            href: "/interior-house-painting",
                            text: "Learn more..."

                        },
                        expanded: false 
                    }
                },
                {
                    image: {
                        alt: "surfaces paintings",
                        src: "/images/pinturas-exteriores.png",
                        title: "Surfaces paintings",
                        className: "card-image-position"
                    },
                    content: {
                        href: "/wood-painting",
                        title: "Surfaces paintings",
                        intro: "Our teams of painters are specialized in painting various surfaces of a dwelling.",
                        resume: "All types of paint coating material on walls and ceilings. If you want to learn more about our service please contact us.",
                        more: {
                            href: "/wood-painting",
                            text: "Learn more..."
                        },
                        expanded: false,
                        className: "card-2-content-size"
                    }
                },
                {
                    image: {
                        alt: "Painting walls and ceilings",
                        src: "/images/pinturas-tetos.jpg",
                        title: "Painting walls and ceilings"
                    },
                    content: {
                        href: "/walls-ceilings-painting",
                        title: "Painting walls and ceilings",
                        intro: "Painters PAINT4U are specialized in interior painting.",
                        resume: "With our teams of painters, the painted ceilings and walls of your home will be as new. If you're wondering what the best paint to use, our technicians will be happy to help.",
                        more: {
                            href: "/walls-ceilings-painting",
                            text: "Learn more..."
                        },
                        expanded: false,
                        className: "card-3-content-size" 
                    }
                },
                {
                    image: {
                        alt: "walls painting",
                        src: "/images/pinturas-paredes.jpg",
                        title: "Painting of tourist homes"
                    },
                    content: {
                        href: "/painting-rented-apartments-turism-places",
                        title: "Painting of tourist homes",
                        intro: "If you have houses or tourist apartments for rent knows the importance of painting the picture that conveys to its customers.",
                        resume: "The service Paint4U tourist apartments of paintings was designed to ensure a quick, clean and economical service performed in a single day. Contact us.",
                        more: {
                            href: "/painting-rented-apartments-turism-places",
                            text: "Learn more..."
                        },
                        expanded: false 
                    }
                },
                {
                    image: {
                        alt: "shop painting",
                        src: "/images/pintura-lojas.jpg",
                        title: "Shop painting"
                    },
                    content: {
                        href: "/shops-establishments-painting",
                        title: "Shop painting",
                        intro: "The renewal of the decoration and painting of space often gives that special touch that the customer demand.",
                        resume: "With Paint4U can have your shop or painted commercial space from one day to the next without a hitch. Also we made paintings during evening hours. Contact us.",
                        more: {
                            href: "/shops-establishments-painting",
                            text: "Learn more..."
                        },
                        expanded: false,
                        className: "card-5-content-size"
                    }
                },
                {
                    image: {
                        alt: "Recovery molds and Saltpeter",
                        src: "/images/limpeza-pintura-bolores.jpg",
                        title: "Recovery molds and Saltpeter"
                    },
                    content: {
                        href: "/moisture-mould-remediation",
                        title: "Recovery molds and Saltpeter",
                        intro: "The derived from molds and salitres problems associated with humidity.",
                        resume: "The teams of professional painters of PAINT4U are formed to solve much more than a simple painting. Our technicians perform a diagnosis of provenance Humidity developing your request to repair and recover all the affected areas.",
                        more: {
                            href: "/moisture-mould-remediation",
                            text: "Learn more..."
                        },
                        expanded: false,
                        className: "card-6-content-size"
                    }
                }
            ]
        };
        return content[!language ? ModuleUtils.getLanguage() : language]; 
    }

    static getFooterContent(language?: string) {
        let content: any = {
            pt: {
                header1: "SERVIÇOS de PINTURA QUE EXECUTAMOS",
                header2: "SATISFAÇÃO DOS NOSSOS CIENTES",
                testimonials: [
                    {
                       paragraph: `O Sr. Pedro Jacinto ao nos dar o orçamento deixou-nos à 
                                vontade face ao seu profissionalismo. Como o valor foi bastante 
                                aceitavel decidimos começar e ainda hoje estamos deslumbrados pela 
                                rapidez, pontualidade e eficiencia que nos permitio ter a nossa casa 
                                pintada e pronta a habitar num dia de trabalho. Obrigado Jacinto os miudos estão a adorar...`,
                        header: "Familia Marques - Cliente PAINT4U Setúbal"
                    },
                    {
                        paragraph: `O que verdadeiramente me deixou satisfatóriamente admirada foi a 
                                    pontualidade na hora da chegada e a aparencia da equipa de pintores. 
                                    A equipa estava devidamente identificada e nem pareciam ser pintores uma vez 
                                    que a farda estava impecável. Depois sai para o trabalho e não sei o que aconteceu...
                                    Ao chegar estava tudo acabado e nem parecia terem estado a pintar estava tudo impecável.`,
                        header: "Marta Mello - Clente PAINT4U Azeitão"
                    },
                    {
                        paragraph: `Solicitei um orçamento para a pintura da minha moradia a resposta foi breve 
                                    e o técnico compareceu no local há hora acordada. O Sr. Cláudio 
                                    foi muito profissional e prestável face ao serviço a efetuar. A Pintura e 
                                    respectiva limpeza correu muito bem sem nada a apontar e espero que a qualidade e o 
                                    profissionalismo se mantenha, porque quando necessitar, não contactarei mais ninguem.`,
                        header: "Helena Alves Ribeiro - Cliente PAINT4U Palmela"
                    },
                    {
                        paragraph: `Quando preciso de pinturas contacto sempre o Jacinto. Já faz parte da familia, 
                                    o pai do Jacinto pintava a casa da minha avó e da minha mãe. Mais tarde 
                                    com a idade o pai do Jacinto deixou as pinturas e ficou o Jacinto. Não posso estar 
                                    mais satisfeito, porque sempre que efetuou pinturas na minha casa ou de familiares nunca ficou nada por apontar.`,
                        header: "Casimiro Batista - Cliente PAINT4U Setúbal"
                    },
                    {
                        paragraph: `Aproveito para mais uma vez agradecer o profissionalismo e empenho com que foi realizado o trabalho. 
                                    Darei referencias e boas indicações a quem precise de serviço de pintura.`,
                        header: "Miguel Gonçalves - Cliente da Paint4U Lisboa"
                    },
                    {
                        paragraph: `Apreciei bastante a vossa forma de trabalhar, com um plano definido, ajustado para fazer face aos imprevistos. 
                                    Realizado no tempo e or&ccedil;amento combinado, com uma qualidade impecável. Pelo profissionalismo do trabalho 
                                    executado, pela postura de cuidado e disponibilidade na movimentação dos móveis, e a preocupação na limpeza 
                                    final quero deixar um agradecimento à vossa equipa.`,
                        header: "Margarida Machado - Cliente da Paint4U Lisboa"
                    },
                    {
                        paragraph: `Terei todo o gosto em recomendar a Paint4U, que com disponibilidade e profissionalismo demonstrado, marcam a 
                                    diferença na qualidade de serviço.`,
                        header: "Carlos Ferreira - Cliente da Paint4U Sintra"
                    }
                ],
                newsLetter:  "Subscreva a nossa newsletter",
                newsLetterPage: "/confirma-subscricao",
                socialWeb: "Siga-nos nas redes sociais:",
                partners: {
                    title: "Parceiros PAINT4U",
                    primeClean: "Limpezas Prime Clean",
                    robbialac: "Tintas Robbialac",
                    cin: "Tintas CIN",
                    karcher: "Karcher"
                },
                servicesTitle: "SERVIÇOS - PAINT4U",
                services: [
                    {
                        title: "Pinturas de interiores",
                        href: "/pintura-interiores"
                    },
                    {
                        title: "Pinturas de superfícies",
                        href: "/pintura-paredes-tetos"
                    },
                    {
                        title: "Pinturas de Madeiras",
                        href: "/pintura-madeiras"
                    },
                    {
                        title: "Pinturas de anti-fungos",
                        href: "/pintura-antifungos"
                    },
                    {
                        title: "Tratamento de salitres",
                        href: "/tratamento-salitre"
                    },
                    {
                        title: "Pinturas pós-Incêndios",
                        href: "/pintura-pos-incendios"
                    }
                ],
                workTitle: "TRABALHOS PAINT4U",  
                notify: {
                    title: "Noticias - Paint4U",
                    href: "/lavagem-pressao-pintar",
                },
                blog: {
                    title: "BLog pinturas - Paint4u",
                    href: "/inicio",
                },
                contacts: {
                    title: "NOSSA LOCALIZAÇÃO",
                    paragraph: `A Paint4U disponibiliza os seus serviços de 
                                Pintura no distrito de Setúbal e Lisboa. 
                                Para saber mais sobre os nossos serviços e garantias, 
                                contacte-nos!`,
                    phone: {
                        num1: "211 165 327",
                        num2: "966 758 607"
                    },
                    address: "Rua Duque de Palmela, Quinta do Anjo",
                    email: "geral@paint4u.pt",
                    href: "/orcamento-pinturas"
                },
                bottom: {
                    paragraph: "Copyright © 2014",
                    href: "/inicio",
                    title: "empresa de pinturas",
                    text: " Paint4U - Portugal"
                }
            },
            en: {
                header1: "PAINTING SERVICES THAT WE PERFORM",
                header2: "CLIENTS SATISFACTION",
                testimonials: [
                    {
                       paragraph: `When I need always contact the paintor Jacinto. 
                                    He is already part of the family, Jacintos father 
                                    painted the house of my mother and grandmother. 
                                    Later at the age Jacintos father left the paintings 
                                    and was Jacinto who took his place. I can not be more 
                                    pleased, because whenever I made paintings in my home 
                                    and family I never got anything for pointing.`,
                        header: "Marques Family -PAINT4U Customer Setúbal"
                    },
                    {
                        paragraph: `What really made me satisfactorily admired was the on-time 
                                    arrival and the appearance of the team of painters. The team 
                                    was duly identified and neither seemed to be painters since 
                                    the uniform was spotless. After leaves for work we do not 
                                    know what happened... When we came home everything was 
                                    impeccable.`,
                        header: "Marta Mello - PAINT4U Customer Azeitão."
                    },
                    {
                        paragraph: `I requested a quote for painting my house the response was 
                                    brief and a technical appear in the site has agreed. The paintor 
                                    was very professional and helpful over the service to perform. 
                                    Paintings went very well with nothing to aim with high quality 
                                    and professionalism. Now I know we dont need no one else for the 
                                    paintings.`,
                        header: "Helena Alves Ribeiro - PAINT4U Customer Palmela"
                    },
                    {
                        paragraph: `When I need paintings I always contact Jacinto. He's already part of the family,
                                    Jacinto's father painted my grandmother's and my mother's house. Later
                                    with age Jacinto's father left the paintings and Jacinto remained. I can't be
                                    more satisfied, because whenever he made paintings at my home or family he never left anything to be done.`,
                        header: "Casimiro Batista - PAINT4U Customer Setúbal"
                    },
                    {
                        paragraph: `I would like to take this opportunity once again to thank the professionalism and commitment with which the work 
                                    was carried out.
                                    I will give references and good indications to anyone who needs painting service.`,
                        header: "Miguel Gonçalves - PAINT4U Customer Lisboa"
                    },
                    {
                        paragraph: `I really appreciated your way of working, with a defined plan, adjusted to face unforeseen circumstances.
                                    Made in time and combined budget, with impeccable quality. For the professionalism of the work
                                    executed, due to the attitude of care and availability in moving furniture, and the concern with cleaning
                                    in the end. I want to say thanks to your team.`,
                        header: "Margarida Machado - PAINT4U Customer Lisboa"
                    },
                    {
                        paragraph: `I will be happy to recommend Paint4U, which with availability and demonstrated professionalism,
                                    mark the difference in quality of service.`,
                        header: "Carlos Ferreira - PAINT4U Customer Sintra"
                    }
                ],
                newsLetter:  "Subscribe to our newsletter",
                newsLetterPage: "/confirm-subscription",
                socialWeb: "Follow us on social networks:",
                partners: {
                    title: "PAINT4U PARTNERS",
                    primeClean: "Prime Clean",
                    robbialac: "Robbialac",
                    cin: "CIN",
                    karcher: "Karcher"
                },
                servicesTitle: "PAINT4U - SERVICES",
                services: [
                    {
                        title: "Interior Paintings",
                        href: "/interior-paintings"
                    },
                    {
                        title: "Surfaces paintings",
                        href: "/walls-ceilings-painting"
                    },
                    {
                        title: "Wood Paintings",
                        href: "/wood-painting"
                    },
                    {
                        title: "Anti-fungal paintings",
                        href: "/anti-fungal-painting"
                    },
                    {
                        title: "Treatment saltpeter",
                        href: "/treatment-repair-saltpeter"
                    },
                    {
                        title: "After Fire Paintings",
                        href: "/fire-smoke-damage-restoration"
                    }
                ],
                workTitle: "PAINT4U WORKS",  
                notify: {
                    title: "Paint4u News",
                    href: "/pressure-wash-house",
                },
                blog: {
                    title: "Paint4u - Paintings Blog",
                    href: "/home",
                },
                contacts: {
                    title: "OUR LOCATION",
                    paragraph: `The Paint4U Painting provides its services in the 
                                district of Setúbal and Lisbon. To learn more about 
                                our services and guarantees, please contact us!`,
                    phone: {
                        num1: "211 165 327",
                        num2: "966 758 607"
                    },
                    address: "Street Duque de Palmela, Quinta do Anjo",
                    email: "geral@paint4u.pt",
                    href: "/paint-budget"
                },
                bottom: {
                    paragraph: "Copyright © 2014",
                    href: "/home",
                    title: "painting company",
                    text: " Paint4U - Portugal"
                }
            }
        };
        return content[!language ? ModuleUtils.getLanguage() : language]; 
    }
}