import { store } from 'modules/app/store';

export class ModuleUtils {

    static gotoPage = (path: string, props: any, navigateToTop: boolean) => {
        const { history } = props;
        if(history && path){
            history.push({pathname:`../${path}`});
        }
        if(navigateToTop) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
        }
    }

    static  getLanguage() {
        let { language } = store.getState().workspace.header;
        return language;
    }
}